(function ($, Drupal, document) {
  var attached = false;

  Drupal.behaviors.customCollectionSortV1 = {
    attach: function (context) {
      if (attached) {
        return;
      }

      attached = true;

      this.context = context;

      this.setEvents();
    },

    setEvents: function () {
      $('.js-custom-collection-sort-v1 .js-mpp-sort__menu', this.context).on('change', function () {
        $(document).trigger('customCollection:sorting', this.value);
      });
    }
  };
})(jQuery, Drupal, document);
